html {
    min-height: 100vh
}

.MuiBox-root .richtext-image.full-width {
  width: 80vw !important;
  height: auto !important;
  display: flex !important;
  margin: 0 auto !important;
}

.MuiBox-root .richtext-image.left, .MuiBox-root .richtext-image.right {
  width: 25vw !important;
  height: auto !important;
  display: flex !important;
  margin: 0 auto !important;
}

.MuiDataGrid-viewport,
.MuiDataGrid-row,
.MuiDataGrid-renderingZone {
    max-height: fit-content !important;
}

/* Allows the MUI grid rows to expand to child height */
.MuiDataGrid-cell {
    max-height: fit-content !important;
    overflow: auto;
    white-space: initial !important;
    /* line-height: 16px !important; */
    display: flex !important;
    align-items: center;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.MuiDataGrid-columnHeaderTitle {
  text-overflow: clip!important;
  white-space: break-spaces!important;
  line-height: 1.2!important;
}

.risk-scores-class {
    border-bottom: 2px solid black;
}

.text-center {
    text-align: center;
}

.risk-button {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
}

.risk-button {
  width: 40vw;
  height: 40vw;
}

.risk-button > span > h3 {
  font-size: large;
  color: white;
}

.homepage-title {
  position: absolute;
  color: #fff;
  font-size: 2em;
  top: 100px;
  font-family: WWFRegular;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
}

.logo {
  height: 80px;
  z-index: 3000;
  
  position: absolute;
}

@media screen and (min-width: 426px) {
  .homepage-title {
    font-size: 3rem;
    top: auto;
  }

  .logo {
    left: 15px; 
    height: 100px
  }
}

@media screen and (min-width: 526px) {
  .risk-button {
    width: 25vw;
    height: 25vw;
  }

  .risk-button > span > h3 {
    font-size: x-large;
  }
}

@media screen and (min-width: 769px) {
  .homepage-title {
    font-size: 4rem;
    top: auto;
  }

  .logo {
    height: 130px;
  }

  .risk-button > span > h3 {
    font-size: xx-large;
  }
}

